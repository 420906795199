<template>
  <div class="wrapper">
    <div class="section adver">
      <div class="adver-swiper">
        <el-carousel indicator-position="none" height="400px" arrow="always">
          <el-carousel-item v-for="(item, index) in adver.swiper" :key="index">
            <a :href="item.link" target="_blank">
              <img :src="assetsUrl + item.imgUrl" alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="adver-poster">
        <a :href="adver.poster.link" target="_blank"><img :src="assetsUrl + adver.poster.imgUrl"
            :alt="adver.poster.title" /></a>
      </div>
      <div class="adver-poster horizontal">
        <a :href="adver.poster.link" target="_blank" :style="{
          backgroundImage: `url(${assetsUrl + adver.poster.imgUrlHorizontal
            })`,
        }">
          <p class="adver-title">
            <span class="adver-title__main">{{ adver.poster.title }}</span>
            <span class="adver-title__sub">{{ adver.poster.subTitle }}</span>
          </p>
          <p class="adver-desc">注：{{ adver.poster.description }}</p>
        </a>
      </div>
    </div>

    <div class="section entrance">
      <SectionTitle title="找资源">
        <p class="section-title post-btn">
          <a href="/resources/post">
            <span class="section-title-sub post-btn-title">资源捐赠</span>
            <span class="post-btn-icon">
              <img class="normal" src="../../assets/imgs/right_circle_normal_icon.png" alt="" />
              <img class="active" src="../../assets/imgs/right_circle_active_icon.png" alt="" />
            </span>
          </a>
        </p>
      </SectionTitle>
      <ul class="nav-list-wrapper">
        <li class="nav-list-item" @click="goCategory(item)" v-for="(item, index) in categoryList" :key="index">
          <img class="item-icon" :src="assetsUrl + '/assets/imgs/icons/' + item.icon" />
          <p class="item-label">
            <span class="item-label__cn">{{ item.label }}</span>
            <span class="item-label__en">{{ item.color }}</span>
          </p>
        </li>
      </ul>
    </div>

    <div class="section recommend" ref="recommend">
      <SectionTitle title="热门推荐">
        <p class="section-title">
          <span class="section-title-sub">优质资源推荐</span>
        </p>
        <p class="recommend-category">
          <span class="recommend-category-item" :class="{ active: activeCategoryIndex == index }"
            v-for="(item, index) in categoryList" :key="index" @click="selectRecommendCateory(item, index)">
            {{ item.label }}
          </span>
        </p>
      </SectionTitle>
      <!-- <ul class="recommend-list">
        <li class="recommend-item" v-for="(item, index) in recommendList" :key="index">
          <a class="recommend-item__inner" :href="'/resources/detail?id=' + item.id">
            <img class="recommend-item-poster" :src="assetsUrl + item.poster" :alt="item.resourceName">
            <p class="recommend-item-title" :title="item.resourceName">{{ item.resourceName }}</p>
          </a>
        </li>
      </ul> -->
      <div class="recommend-list">
        <WaterfallRecommend :data="recommendList"></WaterfallRecommend>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdverInfo } from "../../api/setting";
import { setOptions } from "../../utils";
import { getHomeRecommendResources } from "../../api/resources";
export default {
  data() {
    return {
      adver: {
        swiper: [],
        poster: {},
      },
      activeCategoryIndex: 0, // 当前选中的推荐分类
      recommendList: [], // 推荐数据列表
    };
  },
  computed: {
    assetsUrl() {
      return this.$store.state.assetsUrl;
    },
    categoryList() {
      return setOptions("category_list");
    },
    recommendOffsetTop() {
      return (this.$refs.recommend && this.$refs.recommend.offsetTop) || 0;
    },
  },
  components: {
    SectionTitle: () => import("../../components/common/SectionTitle.vue"),
    WaterfallRecommend: () =>
      import("../../components/resources/WaterfallRecommend.vue"),
  },
  methods: {
    /**
     * @name 根据类型跳转对应分类页面
     * @param {Object} item 类别对象
     */
    goCategory(item) {
      this.$router.push("/community/" + item.value);
    },
    /**
     * @name 获取轮播及海报数据
     */
    getAdver() {
      getAdverInfo()
        .then((res) => {
          this.adver = res.data;
          this.$nextTick(() => {
            console.log(window.innerHeight, this.recommendOffsetTop);
            if (window.innerHeight >= this.recommendOffsetTop - 50) {
              this.getRecommendList();
            } else {
              window.addEventListener("scroll", this.scrollEvent);
            }
          });
        })
        .catch((err) => {
          this.$message.error("网络开车小差儿了，似乎有些问题");
        });
    },
    /**
     * @name 切换分类
     */
    selectRecommendCateory(item, index) {
      this.activeCategoryIndex = index;
      this.getRecommendList();
    },
    /**
     * @name 根据选中分类获取推荐数据
     * @description 如果此时分类数据还未被初始化，那么停留300毫秒后，重新获取
     */
    getRecommendList() {
      if (this.categoryList.length == 0)
        return setTimeout(() => {
          this.getRecommendList;
        }, 300);
      getHomeRecommendResources({
        category: this.categoryList[this.activeCategoryIndex].value,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);
        this.recommendList = res.data || [];
      });
    },
    /**
     * @name 监听页面滚动到推荐部分后，加载数据
     *
     * @description 留出50厘米的下边距，或者偏移量小于当前页面高度的
     */
    scrollEvent(e) {
      let currentOffsetTop = (window.scrollY || 0) + window.innerHeight;
      if (currentOffsetTop >= this.recommendOffsetTop - 50) {
        // 获取数据，并销毁滚动监听
        this.getRecommendList();
        window.removeEventListener("scroll", this.scrollEvent);
      }
    },
  },
  created() {
    this.getAdver();
  },
  mounted() { },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollEvent);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0;

  .section {
    margin-bottom: 100px;

    .section-title {
      display: inline-block;

      .section-title-sub {
        font-size: 16px;
        color: #aeaeae;
        font-style: normal;
        vertical-align: middle;
      }
    }
  }

  .adver {
    display: flex;
    justify-content: space-between;

    .adver-poster {
      width: 286px;
      height: 400px;

      &.horizontal {
        display: none;
        width: 100%;
      }
    }

    .adver-swiper {
      width: calc(100% - 300px);
      margin-right: 14px;
      height: 400px;

      a {
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          min-height: 100%;
        }
      }
    }

    .adver-poster,
    .adver-swiper {
      a {
        display: block;
        width: 100%;
        height: 100%;

        img {
          min-width: 100%;
          height: 100%;
        }
      }
    }
  }

  .entrance {
    min-height: 180px;

    .nav-list-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #999;
      }

      .nav-list-item {
        position: relative;
        width: calc(20% - 16px);
        max-width: 295px;
        min-width: 147px;
        margin-right: 20px;
        cursor: pointer;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 4px;

        .item-label {
          position: absolute;
          top: 20px;
          left: 20px;
          z-index: 2;
          color: #fff;
          font-size: 18px;

          span.item-label__cn {
            font-weight: bold;
            display: block;
          }

          span.item-label__en {
            display: block;
            font-size: 14px;
            opacity: 0.8;
          }
        }

        .item-icon {
          width: 100%;
          display: block;
          max-height: 150px;
          transition: 0.3s;
        }

        &:nth-last-of-type(1) {
          margin-right: 0;
        }

        &.active,
        &:hover {
          .item-label {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            span.item-label__cn {
              font-size: 20px;
            }

            span.item-label__en {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 16px;
              opacity: 0.5;
              line-height: 48px;
            }
          }

          .item-icon {
            filter: blur(5px);
          }
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 2px;
      }
    }

    .post-btn {
      cursor: pointer;
      position: relative;
      padding-right: 40px;

      .post-btn-title {
        margin-right: 20px;
      }

      .post-btn-icon {
        width: 24px;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          vertical-align: middle;
        }

        .normal {
          display: inline-block;
        }

        .active {
          display: none;
        }

        &:hover {
          .normal {
            display: none;
          }

          .active {
            display: inline-block;
          }
        }
      }
    }
  }

  .recommend {
    .recommend-category {
      float: right;

      .recommend-category-item {
        font-size: 14px;
        color: #000;
        cursor: pointer;
        margin-right: 20px;
        text-align: center;
        display: inline-block;
        border-bottom: 2px solid transparent;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }

        &.active {
          font-size: 16px;
          border-bottom: 2px solid #fa6400;
          color: #fa6400;
        }
      }
    }

    .recommend-list {
      overflow: auto;
      width: 100%;
    }
  }
}

@media screen and(max-width: 992px) {
  .wrapper {
    .adver {
      flex-wrap: wrap;

      .adver-swiper {
        width: 100%;
        margin-right: 0;
      }

      .adver-poster {
        display: none;

        &.horizontal {
          width: 100%;
          display: block;
          height: inherit;
          margin-top: 20px;

          a {
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 20px;
            border-radius: 4px;

            .adver-title {
              color: #fff;
              font-size: 16px;

              span {
                vertical-align: middle;
                display: inline-block;
                line-height: 24px;
              }

              .adver-title__main {
                padding-right: 20px;
                margin-right: 20px;
                border-right: 2px solid rgba(255, 255, 255, 0.4);
              }
            }

            .adver-desc {
              font-size: 14px;
              color: #cdcdcd;
              margin-top: 8px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 768px) {
  .wrapper {
    .recommend {
      .recommend-category {
        float: none;
        display: block;
        margin-top: 5px;

        .recommend-category-item {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media screen and(max-width: 480px) {
  .wrapper {
    .adver {
      .adver-poster {
        &.horizontal {
          a {
            .adver-title {
              .adver-title__main {
                border-right: none;
              }

              .adver-title__sub {
                font-size: 14px;
                color: #eaeaea;
              }
            }
          }
        }
      }
    }
  }
}
</style>